import { Solution, SOLUTIONS_NL } from '@energiebespaarders/constants';
import { Box, Flex, Icon, Image, Modal } from '@energiebespaarders/symbols';
import { ModalProps } from '@energiebespaarders/symbols/components/Modal';
import { Large, Medium } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { Alert } from '@energiebespaarders/symbols/icons/solid';
import React from 'react';
import styled from 'styled-components';
import illustrations from 'public/img/illustrations/cutouts/solutionIllustrations';
import { boxShadow } from '../../styles/mixins';
import { ResultCardText } from './utils';

const StyledModalIllustration = styled(Image)`
  ${boxShadow('sm')};
  border: 3px solid white;
  border-radius: 100%;
`;

interface ResultInfoModalProps extends ModalProps {
  resultCardText: ResultCardText;
  solution: Solution;
}
const ResultInfoModal: React.FC<ResultInfoModalProps> = ({
  resultCardText,
  solution,
  ...modalProps
}) => {
  const isMobile = useIsMobile();
  const nounSolutions: Solution[] = [
    Solution.CentralHeatingBoiler,
    Solution.HybridHeatPump,
    Solution.ElectricHeatPump,
  ];
  return (
    <Modal
      buttons={[
        {
          bgColor: 'red',
          inverse: true,
          label: 'Sluiten',
          onClick: modalProps.onRequestClose,
        },
      ]}
      mobile={isMobile}
      title={`Meer info over ${SOLUTIONS_NL[solution].toLowerCase()}`}
      {...modalProps}
    >
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1 / 4]} px={2}>
          <StyledModalIllustration alt={SOLUTIONS_NL[solution]} src={illustrations[solution]} />
        </Box>
        <Box width={[1, 1, 3 / 4]} px={2}>
          {resultCardText.infeasible && (
            <>
              <Icon icon={Alert} solid fill="orange" />{' '}
              <Medium>Deze oplossing lijkt niet mogelijk of rendabel of in jouw situatie</Medium>
              <p>
                {resultCardText.infeasible} Je kunt alsnog de berekeneningen bekijken, mocht onze
                haalbaarheidsanalyse het mis hebben.
              </p>
            </>
          )}

          <Large>
            <Medium>
              Waarom {nounSolutions.includes(solution) ? 'een' : ''}{' '}
              {SOLUTIONS_NL[solution].toLowerCase()}?
            </Medium>
          </Large>

          <p>{resultCardText.default}</p>
          {resultCardText.extra && <p>{resultCardText.extra}</p>}
        </Box>
      </Flex>
    </Modal>
  );
};

export default ResultInfoModal;
