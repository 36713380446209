import { Solution, SOLUTIONS_NL } from '@energiebespaarders/constants';
import { Box, Card, Checkbox, Flex, Icon, Image, Tooltip } from '@energiebespaarders/symbols';
import { Book } from '@energiebespaarders/symbols/helpers';
import { Alert } from '@energiebespaarders/symbols/icons/solid';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import illustrations from 'public/img/illustrations/cutouts/solutionIllustrations';
import { boxShadow } from '../../styles/mixins';
import theme from '../../styles/theme';
import { resultsPage_house_estimates } from '../../types/generated/resultsPage';
import ResultCardCalculations from './ResultCardCalculations';
import { ignoredSolutions } from './ResultCards';
import ResultInfoModal from './ResultInfoModal';
import ResultRibbons from './ResultRibbons';
import { ResultCardText } from './utils';

export const StyledResultCard = styled(Card)`
  position: relative;
  min-height: 180px;
  transition: transform 0.2s ${x => x.theme.curves.standard};

  &:hover {
    transform: translateY(-3px);
  }
`;

interface CardImageProps {
  $isActive?: boolean;
  $isInfeasible?: boolean;
  $bgColor?: string;
  $readonly?: boolean;
}

export const StyledCardImage = styled(Image)<CardImageProps>`
  ${boxShadow('sm')};
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 1px solid white;
  border-radius: 100%;
  background: ${x => x.$bgColor || 'white'};
  pointer-events: ${x => (x.$readonly ? 'none' : 'unset')};
  cursor: pointer;
  filter: ${x => (!x.$isActive ? 'contrast(20%) brightness(150%)' : 'none')};
  transition: all 0.2s ${x => x.theme.curves.standard};

  &:hover {
    transform: translateY(-1px);
    filter: none;
  }

  &:active {
    transform: translateY(1px);
  }

  ${mediaMin.md} {
    margin-top: -50px;
    width: 100px;
    height: 100px;
    border-width: 3px;
  }
`;

const StyledCardTab = styled.div<{ isActive: boolean }>`
  font-size: ${x => x.theme.type.scale[7]};

  ${x =>
    x.isActive
      ? css`
          color: ${x.theme.colors.green};
          font-weight: 500;
        `
      : css`
          color: ${x.theme.colors.grayDark};
          cursor: pointer;

          &:hover {
            color: ${x.theme.colors.grayDarker};
          }
        `}
`;

interface ResultCardProps {
  comfortRating: number;
  currentEnergyIndex: number;
  currentGlazing: { living: string; sleeping: string };
  emissionRating: number;
  estimate: resultsPage_house_estimates;
  resultCardTexts: { [x in Solution]: ResultCardText };
  hasQuickestPayback: boolean;
  hasGreatestImpact: boolean;
  hasMostComfort: boolean;
  hideCheckbox: boolean;
  isExtraInsulation: boolean;
  toggleActiveCallback: (solutions: Solution, overrideState?: boolean) => void;
  showEnergyLabelPrediction?: boolean;
}

const ResultCard: React.FC<ResultCardProps> = ({
  comfortRating,
  currentEnergyIndex,
  currentGlazing,
  emissionRating,
  estimate,
  resultCardTexts,
  hasQuickestPayback,
  hasGreatestImpact,
  hasMostComfort,
  hideCheckbox,
  isExtraInsulation,
  showEnergyLabelPrediction,
  toggleActiveCallback,
}) => {
  const solution = estimate.solution;
  const defaultActiveSolutions = useMemo(
    () =>
      (Object.keys(resultCardTexts) as Solution[]).filter(
        s =>
          !resultCardTexts[s].infeasible &&
          !ignoredSolutions.includes(s) &&
          s !== Solution.AirToAirHeatPump,
      ),
    [resultCardTexts],
  );

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(defaultActiveSolutions.includes(solution));
  const [activeCardTab, setActiveCardTab] = useState(
    defaultActiveSolutions.includes(estimate.solution) ? 'calculations' : 'explanation',
  );

  const toggleActiveSolution = () => {
    setIsActive(!isActive);
    toggleActiveCallback(solution);
  };

  const solutionTitle = useMemo(() => {
    if (isExtraInsulation) return SOLUTIONS_NL[solution].replace('isolatie', ' na-isolatie');
    if (solution === 'windowGlazing') {
      if (currentGlazing.living.includes('hr') && !currentGlazing.sleeping.includes('hr')) {
        return 'Isolatieglas slaapruimte';
      }
      if (!currentGlazing.living.includes('hr') && currentGlazing.sleeping.includes('hr')) {
        return 'Isolatieglas leefruimte';
      }
    }
    return SOLUTIONS_NL[solution];
  }, [currentGlazing, solution, isExtraInsulation]);
  return (
    <>
      <StyledResultCard
        bgColor={resultCardTexts[solution].infeasible ? 'grayLighter' : 'white'}
        color={resultCardTexts[solution].infeasible ? 'grayDarker' : 'grayBlack'}
      >
        <Flex flexWrap="wrap" alignItems="center">
          <Box width={['50px', '50px', '100px']}>
            <StyledCardImage
              alt={SOLUTIONS_NL[solution]}
              src={illustrations[solution]}
              onClick={() =>
                setActiveCardTab(activeCardTab === 'explanation' ? 'calculations' : 'explanation')
              }
              $isActive={isActive}
              $isInfeasible={!!resultCardTexts[solution].infeasible}
            />
          </Box>
          <Box width={['calc(100% - 50px)', 'calc(100% - 50px)', 'calc(100% - 100px)']} pl={2}>
            {defaultActiveSolutions.length > 1 && (
              <ResultRibbons
                hasQuickestPayback={hasQuickestPayback}
                hasGreatestImpact={hasGreatestImpact}
                hasMostComfort={hasMostComfort}
              />
            )}

            <Book>{solutionTitle}</Book>
            {resultCardTexts[solution].infeasible && (
              <Tooltip bgColor="orange" content="Waarschijnlijk niet mogelijk of rendabel">
                <Icon
                  icon={Alert}
                  solid
                  fill="orange"
                  onClick={() => setActiveCardTab('explanation')}
                  width="1rem"
                  mx={1}
                />
              </Tooltip>
            )}

            <Flex>
              <Box width={1 / 2}>
                <StyledCardTab
                  isActive={activeCardTab === 'calculations'}
                  onClick={() => setActiveCardTab('calculations')}
                >
                  Berekeningen
                </StyledCardTab>
              </Box>
              <Box width={1 / 2}>
                <StyledCardTab
                  isActive={activeCardTab === 'explanation'}
                  onClick={() => setActiveCardTab('explanation')}
                >
                  Toelichting
                </StyledCardTab>
              </Box>
            </Flex>
          </Box>
          <Box width={1} mt={2} alignSelf="center" style={{ fontSize: theme.type.scale[7] }}>
            <ResultCardCalculations
              comfortRating={comfortRating}
              currentEnergyIndex={currentEnergyIndex}
              emissionRating={emissionRating}
              estimate={estimate}
              isExtraInsulation={isExtraInsulation}
              setInfoModalOpen={setInfoModalOpen}
              showEnergyLabelPrediction={showEnergyLabelPrediction}
              showText={activeCardTab === 'explanation'}
              text={resultCardTexts[solution]}
            />
          </Box>
        </Flex>
      </StyledResultCard>
      {!hideCheckbox && (
        <Checkbox
          id={solution}
          name={solution}
          checked={isActive}
          fontSize={7}
          onChange={toggleActiveSolution}
          label="Meenemen in berekening"
          value={solution}
          width="100%"
          mt={1}
          mr={0}
          mb={0}
        />
      )}
      <ResultInfoModal
        isOpen={infoModalOpen}
        onRequestClose={() => setInfoModalOpen(false)}
        resultCardText={resultCardTexts[solution]}
        solution={solution}
      />
    </>
  );
};

export default ResultCard;
