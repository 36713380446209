import { Solution } from '@energiebespaarders/constants';
import { cloneDeep } from 'lodash';
import { roundAmount } from '~/utils/rounding';
import { Writable } from 'src/typeHelpers';
import {
  resultsCardsStandalone_house,
  resultsCardsStandalone_house_savingsCheckResultsTexts,
} from '../../types/generated/resultsCardsStandalone';
import {
  resultsPage_house,
  resultsPage_house_estimates,
  resultsPage_house_savingsCheckResultsTexts,
} from '../../types/generated/resultsPage';
import {
  resultsCards_Userless_findUserlessHouse,
  resultsCards_Userless_findUserlessHouse_savingsCheckResultsTexts,
} from './../../types/generated/resultsCards_Userless';
import { ignoredSolutions } from './ResultCards';

export enum SortingProperty {
  money = 'money',
  paybackTime = 'paybackTime',
  emission = 'emission',
  comfort = 'comfort',
  investment = 'investment',
}

export function sortEstimates(
  estimates: Pick<
    resultsPage_house_estimates,
    'energyDelta' | 'solution' | 'paybackTime' | 'comfortScore' | 'investment'
  >[],
  infeasibleSolutions: string[],
  sortProp: SortingProperty,
): resultsPage_house_estimates[] {
  if (!estimates || estimates.length === 0) return [];
  let feasibleEstimates = [
    ...estimates.filter(e => !infeasibleSolutions.includes(e.solution) && e.energyDelta.money! > 0),
  ];

  switch (sortProp) {
    case SortingProperty.money:
      feasibleEstimates = feasibleEstimates.sort((a, b) =>
        b.energyDelta.money > a.energyDelta.money ? 1 : -1,
      );
      break;

    case SortingProperty.paybackTime:
      feasibleEstimates = feasibleEstimates.sort((a, b) =>
        b.paybackTime > a.paybackTime ? -1 : 1,
      );
      break;

    case SortingProperty.emission:
      feasibleEstimates = feasibleEstimates.sort((a, b) =>
        b.energyDelta.emission > a.energyDelta.emission ? 1 : -1,
      );
      break;

    case SortingProperty.comfort:
      feasibleEstimates = feasibleEstimates.sort((a, b) =>
        b.comfortScore > a.comfortScore ? 1 : -1,
      );
      break;

    case SortingProperty.investment:
      feasibleEstimates = feasibleEstimates.sort((a, b) => (b.investment > a.investment ? 1 : -1));
      break;

    default:
      break;
  }

  const infeasibleEstimates = [
    ...estimates.filter(
      e => infeasibleSolutions.includes(e.solution) || roundAmount(e.energyDelta.money, 10) <= 0,
    ),
  ].sort((a, b) => (b.energyDelta.money > a.energyDelta.money ? 1 : -1));

  // If heating is infeasible, move to the end
  const heatingIndex = infeasibleEstimates.findIndex(e => e.solution === 'centralHeatingBoiler');
  infeasibleEstimates.push(infeasibleEstimates.splice(heatingIndex, 1)[0]);

  const sortedEstimates = feasibleEstimates
    .concat(infeasibleEstimates)
    .filter(e => !ignoredSolutions.includes(e.solution));

  return sortedEstimates as resultsPage_house_estimates[];
}

export const getApartmentPropertiesFromType = (houseType: number) => {
  const apartmentProperties: string[] = [];
  if ([6, 7, 8, 9, 10, 11, 12, 13].some(t => t === houseType)) {
    apartmentProperties.push('hasMultipleStories');
  }
  if ([6, 7, 8, 9, 14, 15, 16, 17].some(t => t === houseType)) {
    apartmentProperties.push('isOnCorner');
  }
  if ([6, 7, 11, 12, 14, 15, 18, 19].some(t => t === houseType)) {
    apartmentProperties.push('hasRoof');
  }
  if ([6, 8, 10, 12, 14, 16, 18, 20].some(t => t === houseType)) {
    apartmentProperties.push('isOnGroundFloor');
  }
  return apartmentProperties;
};

export type ResultCardText = {
  /** This is the default info text for a solution */
  default: string;
  /** An extra paragraph that shows up in the modal */
  extra?: string;
  /** Replaces the text on the card without overwriting the default info */
  extraInsulation?: string;
  /** The text explaining why this solution is not feasible */
  infeasible?: string;
};

type QueryHouse =
  | resultsPage_house
  | resultsCardsStandalone_house
  | resultsCards_Userless_findUserlessHouse;
type QuerySavingsCheckResultsTexts =
  | resultsCardsStandalone_house_savingsCheckResultsTexts
  | resultsPage_house_savingsCheckResultsTexts
  | resultsCards_Userless_findUserlessHouse_savingsCheckResultsTexts;

/** Returns the texts for the result cards
 * @param house The house object from the query
 * @param inLegacyFunnel Whether or not the query is from the legacy funnel (BC results -> request advice) or the new funnel (estimate -> request quotes)
 */
export function getResultsCardTexts(
  house: QueryHouse,
  inLegacyFunnel = true,
): Record<Solution, ResultCardText> {
  const texts: Writable<QuerySavingsCheckResultsTexts> = cloneDeep(house.savingsCheckResultsTexts);

  // TODO:  Next step would be to move all of the money calculations etc to the HS. It's impossible to do this part through
  //        the API until the money calculations are in the HS (currently in Constants). Not sure if that's even what we want
  //        tho because then we can't calculate it on the fly in the FE (however, if it was properly hooked up through the API then
  //        would there be any need to calculate it on the fly in th FE?)
  const NO_SAVINGS =
    'Deze oplossing levert in jouw situatie geen besparing op, maar kan wel een significante verbetering in wooncomfort of toekomstbestendigheid betekenen. Laat je hier bij een huisbezoek over adviseren.';
  house.estimates.map(estimate => {
    if (roundAmount(estimate.energyDelta.money, 10) <= 0) {
      const s = estimate.solution;
      texts[s].infeasible = texts[s].infeasible || NO_SAVINGS;
    }
  });

  // Remove references to house visits and calculations if the user is in the new funnel

  /** TODO: This is another temp ugly solution to something that should be updated in the backend,
  /*`EstimatesHouseData` is fetching an OmniformResponse and puts these in the localStorage when true
  */
  if (!inLegacyFunnel) {
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem(`${house.id}_noWallCavity`) === 'true') {
        texts.wallInsulation.infeasible =
          'Je hebt aangegeven geen spouwmuur te hebben om te isoleren.';
      }
      if (window.localStorage.getItem(`${house.id}_noCrawlspace`) === 'true') {
        texts.floorInsulation.infeasible =
          'Er is geen kruipruimte aanwezig, dus kan de vloer niet geïsoleerd worden.';
        texts.crawlspaceInsulation.infeasible =
          'Er is geen kruipruimte aanwezig, dus kan de vloer niet geïsoleerd worden.';
      }
    }
  }

  return texts;
}

/**
 * ISDE uses a lower tariff when there is only one insulation / glazing (i.e. not heat pump) solution being installed.
 */
export const isLowTariffISDE = (activeSubsidizableSolutions: Solution[]) =>
  activeSubsidizableSolutions.length < 2 &&
  !activeSubsidizableSolutions.includes(Solution.HybridHeatPump) &&
  !activeSubsidizableSolutions.includes(Solution.ElectricHeatPump);
