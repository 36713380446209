import { JSXElement } from '@babel/types';
import { getEnergyLabel, Solution } from '@energiebespaarders/constants';
import {
  Box,
  Counter,
  EnergyLabel,
  Flex,
  Icon,
  TextLink,
  Tooltip,
} from '@energiebespaarders/symbols';
import { Book, Gold, Green } from '@energiebespaarders/symbols/helpers';
import { Alert, Information, LeafAngle, Star } from '@energiebespaarders/symbols/icons/solid';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import { round as lodashRound } from 'lodash';
import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import useABTest from '~/hooks/useABTest';
import { useMeOptional } from '~/hooks/useMe';
import { resultsPage_house_estimates } from '~/types/generated/resultsPage';
import delimit from '~/utils/delimit';
import { roundAmount } from '~/utils/rounding';
import { ResultCardText } from './utils';

const StyledText = styled(Box)`
  ${mediaMin.md} {
    max-height: 200px;
    overflow-y: auto;
  }
`;

interface ResultCardCalculationsProps {
  comfortRating: number;
  currentEnergyIndex: number;
  emissionRating: number;
  estimate: resultsPage_house_estimates;
  isExtraInsulation: boolean;
  setInfoModalOpen: Dispatch<SetStateAction<boolean>>;
  showText: boolean;
  text: ResultCardText;
  showEnergyLabelPrediction?: boolean;
}

const ResultCardCalculations: React.FC<ResultCardCalculationsProps> = ({
  comfortRating,
  currentEnergyIndex,
  emissionRating,
  estimate,
  text,
  isExtraInsulation,
  setInfoModalOpen,
  showText,
  showEnergyLabelPrediction,
}) => {
  const { me } = useMeOptional();
  const { asPath, query } = useRouter();
  const { abTestEntries } = useABTest();
  const inGroupB = useMemo(
    () => abTestEntries?.some(entry => entry.variant === 'deb-savings-monthly'),
    [abTestEntries],
  );

  const params = query as {
    UID?: string;
    noreq?: string;
    ebv?: string;
    bg?: string;
    fullscreen?: string;
    source?: string;
    houseId?: string;
  };

  const onMortgageRoute = asPath.includes('-ebv');
  const isEbvCheck = useMemo(() => {
    if (onMortgageRoute) return true;
    if (me?.__typename === 'PartnerAgent') return true;
    if (params.source === 'adaxio') return true;
    if (params.ebv !== undefined) return Boolean(JSON.parse(params.ebv));
    return false;
  }, [onMortgageRoute, me?.__typename, params.ebv, params.source]);

  const {
    energyDelta: { energyIndex, money },
    investment,
  } = estimate;
  const solution = estimate.solution;
  const roundedSavings = roundAmount(money, 10);

  const labelEstimation = useMemo(
    () => getEnergyLabel(Math.min(currentEnergyIndex, 3) - Math.min(0.35, energyIndex)),
    [currentEnergyIndex, energyIndex],
  );

  const renderCounter = useCallback(
    (value = 0) => (
      <Counter
        value={value}
        formatValue={(n = 0) => delimit(Math.max(0, inGroupB ? n : roundAmount(n, 10)), 0)}
      />
    ),
    [inGroupB],
  );
  const renderIcons = (amount = 1, icon: JSXElement, color: string) =>
    [...Array(amount || 1)].map((_, index) => (
      <Icon
        key={`${solution}-${color}-${index}`}
        icon={icon}
        solid
        fill={color}
        height="1em"
        verticalAlign="text-top"
      />
    ));

  return showText ? (
    <Flex flexWrap="wrap">
      <StyledText width={1} px={2}>
        {text.infeasible || text.extraInsulation || text.default}
      </StyledText>
      <Box width={1} px={2}>
        <Book>
          <TextLink onClick={() => setInfoModalOpen(true)}>Meer lezen</TextLink>
        </Book>
      </Box>
    </Flex>
  ) : (
    <Flex flexWrap="wrap" mx={-1} alignItems="center">
      <Box width={1 / 2} px={1} textAlign="right">
        Besparing
      </Box>
      <Box width={1 / 2} px={1}>
        ±{' '}
        <Book>
          €{renderCounter(money / (inGroupB ? 12 : 1))} / {inGroupB ? 'maand' : 'jaar'}{' '}
        </Book>
        {(solution === 'hybridHeatPump' || solution === 'electricHeatPump') &&
          (roundedSavings <= 0 ? (
            <Tooltip
              bgColor="orange"
              content="Is de besparing €0? Dat betekent dat je waarschijnlijk meer aan extra stroomverbruik zou betalen dan aan gas zou besparen."
              fontSize={7}
            >
              <Icon icon={Alert} fill="orange" mx={1} />
            </Tooltip>
          ) : (
            <Tooltip
              bgColor="blue"
              content={`Deze berekeningen zijn op basis van een volledig geïsoleerde schil met Rc > ${
                solution === 'electricHeatPump' ? '3,5' : '2'
              }.`}
              fontSize={7}
            >
              <Icon icon={Information} fill="blue" mx={1} />
            </Tooltip>
          ))}
        {isExtraInsulation && (
          <Tooltip
            bgColor="blue"
            content="De besparingen kunnen hier lager uitvallen omdat het na-isolatie betreft. Deze is echter sterk afhankelijk van de staat van de huidige isolatie. Laat onze adviseurs tijdens het huisbezoek een kijkje nemen om het in jouw situatie door te rekenen."
            fontSize={7}
          >
            <Icon icon={Information} fill="blue" mx={1} />
          </Tooltip>
        )}
      </Box>
      <Box width={1 / 2} px={1} textAlign="right">
        Investering
      </Box>
      <Box width={1 / 2} px={1}>
        ± <Book>€{renderCounter(lodashRound(investment * (isEbvCheck ? 1.2 : 1), -2))}</Book>
      </Box>
      <Box width={1 / 2} px={1} textAlign="right">
        Vermindering uitstoot
      </Box>
      <Box width={1 / 2} px={1}>
        <Green>+</Green> {renderIcons(emissionRating, LeafAngle, 'green')}
      </Box>
      <Box width={1 / 2} px={1} textAlign="right">
        Toename wooncomfort
      </Box>
      <Box width={1 / 2} px={1}>
        <Gold>+</Gold> {renderIcons(comfortRating, Star, 'orange')}
      </Box>

      {showEnergyLabelPrediction && (
        <>
          <Box width={1 / 2} px={1} textAlign="right">
            Indicatie nieuw label
          </Box>
          <Box width={1 / 2} px={1}>
            {[Solution.HybridHeatPump, Solution.ElectricHeatPump].every(s => s !== solution) ? (
              <Tooltip
                bgColor="grayDarker"
                content="We willen benadrukken dat dit een indicatie is o.b.v. beperkte informatie."
                maxWidth="150px"
                placement="bottom"
              >
                <EnergyLabel
                  label={labelEstimation}
                  labelType="registered"
                  fontSize={8}
                  hideTooltip
                />
              </Tooltip>
            ) : (
              <Tooltip
                bgColor="grayDarker"
                content="Labelindicaties voor warmtepompen worden helaas nog niet ondersteund"
                maxWidth="180px"
                placement="bottom"
              >
                <Icon icon={Information} solid fill="grayDarker" width="1.5em" />
              </Tooltip>
            )}
          </Box>
        </>
      )}
    </Flex>
  );
};

export default ResultCardCalculations;
