import { JSXElement } from '@babel/types';
import { Box, Flex, Icon, Tooltip } from '@energiebespaarders/symbols';
import { LeafAngle, PiggyBank, Star } from '@energiebespaarders/symbols/icons/solid';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { boxShadow, pos, themify } from '../../styles/mixins';

type StyledRibbonProps = {
  left?: number;
  right?: number;
  bgColor?: string;
  width?: string;
};

export const StyledRibbon = styled(Box)<StyledRibbonProps>`
  ${x => x.left && pos('left', x.left)};
  ${x => x.right && pos('right', x.right)};
  ${boxShadow('xl')};
  background: ${x => x.bgColor};
  position: absolute;
  top: -3px;
  height: ${x => x.width || '30px'};
  width: ${x => x.width || '30px'};
  text-align: center;
  border-radius: 3px 3px 0 0;
  transition: top 0.15s ${x => x.theme.curves.sharp};
  z-index: 2;

  &:hover {
    top: -1px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: ${x => `calc(-${x.width || '30px'} / 2)`};
  }

  &::before {
    left: 0;
    border-left: ${x => `calc(${x.width || '30px'} / 2) solid ${x.bgColor}`};
    border-right: ${x => `calc(${x.width || '30px'} / 2) solid ${x.bgColor}`};
    border-bottom: ${x => `calc(${x.width || '30px'} / 2) solid transparent`};
  }
`;

interface RibbonProps {
  bgColor: string;
  icon: JSXElement;
  right: number;
  text: ReactNode;
  width?: string;
}

export const Ribbon: React.FC<RibbonProps> = ({ bgColor, icon, right, text, width = '24px' }) => (
  <StyledRibbon bgColor={themify(bgColor)} right={right} width={width}>
    <Tooltip content={text} bgColor={bgColor} color="white" fontSize={6} fontWeight={600}>
      <Icon icon={icon} solid fill="white" width={width} px="3px" pb="3px" />
    </Tooltip>
  </StyledRibbon>
);

interface RibbonsProps {
  hasQuickestPayback: boolean;
  hasGreatestImpact: boolean;
  hasMostComfort: boolean;
}

const Ribbons: React.FC<RibbonsProps> = ({
  hasQuickestPayback,
  hasGreatestImpact,
  hasMostComfort,
}) => {
  const ribbons = [];
  if (hasQuickestPayback) {
    ribbons.push({
      key: 'hasQuickestPayback',
      bgColor: 'blue',
      icon: PiggyBank,
      tooltip: 'Snelst terugverdiend',
    });
  }
  if (hasGreatestImpact) {
    ribbons.push({
      key: 'hasGreatestImpact',
      bgColor: 'green',
      icon: LeafAngle,
      tooltip: 'Grootste impact',
    });
  }
  if (hasMostComfort) {
    ribbons.push({
      key: 'hasMostComfort',
      bgColor: 'orange',
      icon: Star,
      tooltip: 'Meeste comfort',
    });
  }
  return (
    <Flex>
      {ribbons.map((ribbon, index) => (
        <Ribbon
          key={ribbon.key}
          bgColor={ribbon.bgColor}
          icon={ribbon.icon}
          right={(0.5 + index) * 5}
          text={ribbon.tooltip}
        />
      ))}
    </Flex>
  );
};

export default Ribbons;
