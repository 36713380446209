import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Flex } from '@energiebespaarders/symbols';
import { Small } from '@energiebespaarders/symbols/helpers';
import { BagEuro, LeafAngle, Star, PiggyBank } from '@energiebespaarders/symbols/icons/solid';
import styled from 'styled-components';
import { SortingProperty } from './utils';

interface ResultSorterProps {
  sortProp: SortingProperty;
  setSortProp: Dispatch<SetStateAction<SortingProperty>>;
}

const StyledSortButton = styled(Button)`
  white-space: nowrap;
`;

const ResultSorter: React.FC<ResultSorterProps> = ({ sortProp, setSortProp }) => {
  return (
    <Flex textAlign="center" flexWrap="wrap" alignItems="center" px={1} pb={2}>
      <Box width={[1, 1, 1, 1]} px={[2, 2, 1]}>
        <Small>Resultaten sorteren op</Small>
      </Box>
      <Box
        width={[1 / 2, 1 / 4, 1 / 4]}
        onClick={() => setSortProp(SortingProperty.paybackTime)}
        px={1}
      >
        <StyledSortButton
          fluid
          iconStart={PiggyBank}
          minimal
          bgColor="purple"
          fontSize={7}
          label="Terugverdientijd"
          isActive={sortProp === SortingProperty.paybackTime}
        />
      </Box>
      <Box
        width={[1 / 2, 1 / 4, 1 / 4]}
        onClick={() => setSortProp(SortingProperty.emission)}
        px={1}
      >
        <StyledSortButton
          fluid
          iconStart={LeafAngle}
          minimal
          bgColor="purple"
          fontSize={7}
          label="Impact"
          isActive={sortProp === SortingProperty.emission}
        />
      </Box>
      <Box
        width={[1 / 2, 1 / 4, 1 / 4]}
        onClick={() => setSortProp(SortingProperty.comfort)}
        px={1}
      >
        <StyledSortButton
          fluid
          iconStart={Star}
          minimal
          bgColor="purple"
          fontSize={7}
          label="Comfort"
          isActive={sortProp === SortingProperty.comfort}
        />
      </Box>
      <Box
        width={[1 / 2, 1 / 4, 1 / 4]}
        onClick={() => setSortProp(SortingProperty.investment)}
        px={1}
      >
        <StyledSortButton
          fluid
          iconStart={BagEuro}
          minimal
          bgColor="purple"
          fontSize={7}
          label="Investering"
          isActive={sortProp === SortingProperty.investment}
        />
      </Box>
    </Flex>
  );
};

export default ResultSorter;
