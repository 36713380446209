/**
 * Rounds the given number to the given number of decimals
 * @example round(1.23456, 2) => 1.23
 * @example round(1.23456, 3) => 1.235
 */
export const roundToDecimals = (number: number, decimals: number): number => {
  return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

/** Rounds the given amount to a multiple of the rounding input
 * @example roundAmount(123, 10) => 120
 * @example roundAmount(123, 100) => 100
 * @example roundAmount(123, 1000) => 0
 */
export const roundAmount = (amount: number, rounding = 10) => {
  return Math.round(amount / rounding) * rounding;
};
